<template>
  <v-row>
    <v-col cols="12">
      <v-toolbar flat color="transparent" height="40">
        <v-toolbar-title>
          <h3 class="headline mb-0 font-weight-bold main--text">
            {{ $t('page_notifications_title') }}
          </h3>
        </v-toolbar-title>

        <v-divider class="mx-4" vertical></v-divider>

        <v-row align="center">
          <v-col v-for="item in menus" :key="item.text" cols="auto">
            <v-btn
              small
              text
              exact
              :to="item.to"
              exact-active-class="primary--text"
              :ripple="false"
              class="text-capitalize font-weight-regular btn-menu"
            >
              {{ item.text }}
            </v-btn>
          </v-col>
        </v-row>

        <v-spacer></v-spacer>

        <v-row class="flex-row-reverse">
          <v-col cols="auto">
            <v-btn
              small
              class="text-capitalize font-weight-regular"
              text
              @click="
                $router.push({
                  name: 'Companies',
                  query: { status: 'Connect', type: 'corporate' }
                })
              "
            >
              <v-icon color="secondary" left>$goBack</v-icon>
              {{ $t('go_back') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
    </v-col>

    <slot name="content"></slot>
  </v-row>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      menus: [
        {
          icon: '',
          text: this.$t('notification_list'),
          to: 'list'
        },
        {
          icon: '',
          text: `${this.$t('draft')} (${this.$store.getters.totalDraftCount})`,
          to: 'draft'
        },
        {
          icon: '',
          text: this.$t('link_post'),
          to: 'linkpost'
        },
        {
          icon: '',
          text: `${this.$t('category')} / ${this.$t('tag')}`,
          to: 'category-tag'
        }
      ]
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
