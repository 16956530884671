<template>
  <Parent>
    <template slot="content">
      <v-dialog max-width="450" v-model="dialog" :persistent="dialogLoading">
        <v-card :loading="dialogLoading">
          <v-card-title>
            {{ $t('page_notifications_list_title') }}
          </v-card-title>

          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>{{ $t('name') }}</th>
                    <th>{{ $t('time_read') }}</th>
                  </tr>
                </thead>

                <tbody v-if="!dialogLoading">
                  <tr
                    v-for="(view, index) in notificationViews"
                    :key="`view-${index}`"
                    @click="openUserDetail(view.user.id)"
                  >
                    <td>
                      {{ `${view.user.last_name} ${view.user.first_name}` }}
                    </td>
                    <td>{{ getTimeFromTimeStamp(view.created_at) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-row align="center">
              <v-col cols="auto">{{ $t('notification_list') }}</v-col>

              <v-spacer></v-spacer>

              <v-col cols="3">
                <v-text-field
                  outlined
                  dense
                  v-on:keyup.13="searchPosts($event)"
                  :placeholder="`${$t('search')}...`"
                  hide-details
                >
                </v-text-field>
              </v-col>

              <v-col cols="auto">
                <v-btn
                  color="primary"
                  text
                  class="text-capitalize font-weight-regular"
                  to="/notification/create"
                >
                  <v-icon left>mdi-plus</v-icon>
                  {{ $t('page_notifications_list_create_new_text') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="px-0">
            <Table
              :headers="headers"
              :items="allNotifications"
              :loading="loading"
              :total-records="
                notificationPagination
                  ? notificationPagination.records_total
                  : 0
              "
              :number-of-pages="
                notificationPagination.total_pages
                  ? notificationPagination.total_pages
                  : 0
              "
              @update:options="updateTable"
              @click:row="goToEdit"
            >
              <template v-slot:item.id="{ item }">
                <span class="primary--text">{{ item.id }}</span>
              </template>

              <template v-slot:item.status="{ item }">
                <span
                  class="text-capitalize"
                  :class="'text-status-' + item.status"
                  >{{ item.status }}</span
                >
              </template>

              <template v-slot:item.type="{ item }">
                <span
                  v-if="item.type"
                  class="text-capitalize"
                  :class="
                    item.type.id === 'important' ? 'text-type-important' : ''
                  "
                  >{{ item.type.name }}</span
                >
              </template>

              <template v-slot:item.category="{ item }">
                <span class="text-capitalize" v-if="item.category">
                  {{ item.category.name }}
                </span>
              </template>

              <template v-slot:item.created_at="{ item }">
                <span class="text-capitalize">{{ item.created_at }}</span>
              </template>

              <template v-slot:item.tags="{ item }">
                {{
                  Array.prototype.map.call(item.tags, s => s.name).join(', ')
                }}
              </template>

              <template v-slot:item.group="{ item }">
                {{
                  Array.prototype.map.call(item.group, s => s.name).join(', ')
                }}
              </template>

              <template v-slot:item.user_notification_read_count="{ item }">
                <v-col
                  @click.stop="
                    item.user_notification_read_count
                      ? getUserViewedList(item.id)
                      : null
                  "
                  :class="
                    item.user_notification_read_count
                      ? 'text-notification-clickable'
                      : 'text-notification-unclickable'
                  "
                >
                  {{
                    `${item.user_notification_read_count} / ${item.total_user}`
                  }}
                </v-col>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  icon
                  color="secondary"
                  text
                  :to="`/notification/${item.id}/edit`"
                >
                  <v-icon size="12">$edit</v-icon>
                </v-btn>
              </template>
            </Table>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
  </Parent>
</template>

<script>
import Parent from '@/views/admin/Notification/index'
import Table from '@/components/admin/partials/Table/Table'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'index',
  components: { Table, Parent },
  created() {
    this.getDataFromApi()
  },

  computed: {
    ...mapGetters([
      'allNotifications',
      'notificationPagination',
      'notificationViews'
    ])
  },

  data() {
    return {
      headers: [
        { text: this.$t('id'), align: 'center', value: 'id' },
        {
          text: this.$t('page_notifications_list_table_header_title'),
          align: 'center',
          value: 'title'
        },
        {
          text: this.$t('page_notifications_list_table_header_type'),
          align: 'center',
          value: 'type',
          sortable: false
        },
        {
          text: this.$t('page_notifications_list_table_header_category'),
          align: 'center',
          value: 'category'
        },
        {
          text: this.$t('page_notifications_list_table_header_tags'),
          align: 'center',
          value: 'tags',
          sortable: false
        },
        {
          text: this.$t('page_notifications_list_table_header_group'),
          align: 'center',
          value: 'group',
          sortable: false
        },
        {
          text: this.$t('page_notifications_list_table_header_view'),
          align: 'center',
          value: 'user_notification_read_count'
        },
        {
          text: this.$t('page_notifications_list_table_header_created'),
          align: 'center',
          value: 'created_at'
        },
        {
          text: this.$t('actions'),
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
      loading: false,
      initialLoad: true,
      dialog: false,
      dialogLoading: false
    }
  },

  methods: {
    updateTable(e) {
      let obj = {}
      obj.page = e.page
      obj.paginate = e.itemsPerPage
      obj.sortBy = e.sortBy[0]
      obj.sortDesc = e.sortDesc[0]
      if (!this.initialLoad) this.getDataFromApi(obj)
    },
    getDataFromApi(e) {
      this.loading = true
      let data = {}
      if (e?.sortBy !== undefined && e?.sortDesc !== undefined) {
        data.order = e.sortDesc ? 'desc' : 'asc'
        data.sort = e.sortBy
      } else {
        data.sort = 'post_date'
        data.order = 'desc'
      }
      if (e?.page !== undefined && e?.paginate !== undefined) {
        data.page = e.page
        data.paginate = e.paginate
      }
      data.is_draft = 0

      this.$store
        .dispatch('GET_ALL_NOTIFICATIONS', data)
        .then(() => {
          if (this.initialLoad) this.initialLoad = false
        })
        .finally(() => (this.loading = false))
    },
    searchPosts(event) {
      let data = {}
      data.search = event.currentTarget.value
      data.is_draft = 0
      this.$store.dispatch('GET_ALL_NOTIFICATIONS', data)
    },
    getUserViewedList(id) {
      this.dialog = true
      this.dialogLoading = true
      this.$store
        .dispatch('GET_ALL_NOTIFICATION_USER_VIEWS', {
          notification_id: id
        })
        .finally(() => {
          this.dialogLoading = false
        })
    },
    getTimeFromTimeStamp(timestamp) {
      return dayjs(timestamp).format('YYYY/MM/DD HH:mm')
    },
    openUserDetail(id) {
      let routeData = this.$router.resolve({
        name: 'UserDetail',
        params: { id: id }
      })

      window.open(routeData.href, '_blank')
    },
    goToEdit(item) {
      this.$router.push({
        name: 'NotificationEdit',
        params: {
          id: item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
